import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { SceService } from 'src/app/services/sce.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-print-label',
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.scss'],
})
export class PrintLabelComponent implements OnInit {
  labels: any[] = [];
  orderNumbers: any[] = [];
  mealName: string;
  meals: any[] = [];
  day = 'today';
  todayDate;
  tomorrowDate;
  lorem = '';

  constructor(
    private navParams: NavParams,
    private modalCtr: ModalController,
    public sce: SceService
  ) {
    this.mealName = this.navParams.get('mealName');
    this.meals = this.navParams.get('meals');
    this.day = this.navParams.get('day');
    console.log('day->', this.day);
  }

  ngOnInit() {
    const newDate = new Date();
    this.todayDate = moment(newDate).add(3, 'days');
    this.tomorrowDate = moment(newDate).add(4, 'days');

    this.labels = this.meals.filter((el: any) => el.name === this.mealName);

    let orderId = '';
    this.labels.forEach((el) => {
      if (orderId !== el.orderId) {
        orderId = el.orderId;
        this.orderNumbers.push(el);
      }
    });

    console.log('orderNumbers, label ->', this.orderNumbers, this.labels);
  }

  getDishLink(meal: any): string {
    const name = meal.name
      .replace(/\#|\.|\,|\&|\#|\*|\?|\}|\{|\]|\[|\(|\)|%/gi, '')
      .replace(/\s/g, '');
    const url = environment.URL_BASE + 'meal/' + name + '/' + meal.id;
    // console.log('dish url ->', url);
    return url;
  }

  getOrderLink(order: any): string {
    const url =
      environment.URL_BASE +
      'order-details/' +
      (order.id ? order.order_id : '') +
      '/' +
      (order.vendorId ? order.vendorId : '');
    // console.log('order url ->', url);
    return url;
  }
}
