import { Injectable } from '@angular/core';
import { ToastController, NavController } from '@ionic/angular';
import { ApiService } from '../api/api.service';
import { UtilService } from '../util/util.service';
import { SceService } from '../sce.service';

@Injectable()
export class InitUserProvider {
  public loggedInUser: any = {};
  private checkSignedInUserInterv;
  _showUserMenu = false;
  _showSquareDetails;

  constructor(
    private api: ApiService,
    public util: UtilService,
    private toastCtrl: ToastController,
    private sce: SceService,
    private navCtrl: NavController
  ) {
    this.createNewEmptyUser();
  }

  getUserData(): any {
    this.setMenuThenu();

    return this.loggedInUser;
  }

  createNewEmptyUser() {
    this.loggedInUser = {};
    this.sce._loggedInUser = {};
    this._showSquareDetails = '';
  }

  load() {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        console.log('Token load  -> ', token);
        this.api.updateToken(token);
        // this.util.showLoading('', '', 2000);
        this.api.getUser().subscribe(
          (user: any) => {
            //  this.util.hideLoading();
            console.log('load user -> ', user);
            if (user) {
              this.setMenuThenu();
              this.setLoggedInUser(user);
              resolve(true);
            } else resolve(false);
          },
          (err) => {
            // this.util.hideLoading();
            resolve(false);
            console.log(err);
          }
        );
      });
    });
  }

  async setToken(token) {
    this.api.updateToken(token);
    localStorage.setItem('token', token);
  }

  async getToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  async setLoggedInUser(user: any) {
    Object.assign(this.loggedInUser, user);
    this.loggedInUser.token = await this.getToken();
    this.setMenuThenu();
    localStorage.setItem('id', user.id);
  }

  checkSignedInUser() {
    if (!this.loggedInUser.id) clearInterval(this.checkSignedInUserInterv);
    else {
      this.api.getUser().subscribe(
        (res: any) => {
          if (
            res &&
            (!res.signInToken || res.signInToken !== this.sce.signInToken)
          ) {
            clearInterval(this.checkSignedInUserInterv);
            this.navCtrl.navigateRoot('/splash-screen');
          }
        },
        (err) => console.log('3 - checkSignedInUser===>', err)
      );
    }
  }

  setMenuThenu() {
    this.sce._loggedInUser = this.loggedInUser;
    console.log('load user -> ', this.loggedInUser);
    if (this.loggedInUser?.menuTheme) {
      this.sce.activeMenu = this.loggedInUser?.menuTheme.activeMenu;
      this.sce._theme = this.loggedInUser?.menuTheme._theme;
      this.sce._style = this.loggedInUser?.menuTheme._style;
    } else {
      this.sce.activeMenu = [
        { type: 'cat', name: 'Soups' },
        { type: 'cat', name: 'Appetizers' },
        { type: 'cat', name: 'Entrees' },
        { type: 'cat', name: 'Sides' },
        { type: 'cat', name: 'Beverages' },
        { type: 'cat', name: 'Desserts' },
      ];
      this.sce._theme = 1;
      this.sce._style = 'list';
    }
  }
  onNgDestroy() {
    clearInterval(this.checkSignedInUserInterv);
  }

  async logout(): Promise<any> {
    this.createNewEmptyUser();
    await this.api.logout();
    localStorage.clear();
    location.reload();
    return;
  }
}
