import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  ModalOptions,
  NavController,
  ToastController,
} from '@ionic/angular';

import { ComponentRef } from '@ionic/core';

import * as geolib from 'geolib';
declare let google;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  loading: HTMLIonLoadingElement;
  loading2: HTMLIonLoadingElement;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private router: Router
  ) {}

  async getDistanceBtw2points(a: any, b: any) {
    // a, b = { latitude: .., longitude: ... }
    const distance = await geolib.getPreciseDistance(a, b);
    return distance;
  }

  async convertToMiles(d) {
    console.log('distance ----->', d);
    return (0.621371 * d) / 1000;

    //const dist = geolib.convertDistance(d, 'mi')
    //return Math.round(10 * dist) / 10
  }

  goToNew(route) {
    this.navCtrl.navigateRoot(route);
  }

  goBack(route) {
    this.navCtrl.navigateBack(route);
  }

  back() {
    this.navCtrl.back();
  }

  goForward(route) {
    this.navCtrl.navigateForward(route);
  }

  //------------------

  navigateRoot(url: string, options?: any) {
    this.navCtrl.navigateRoot(url, options);
  }
  navigateByUrl(url: string, options?: any) {
    this.router.navigateByUrl(url);
  }

  navigateForward(url: string, options?: any) {
    this.navCtrl.navigateForward(url, options);
  }
  navigateBack(url: string, options?: any) {
    this.navCtrl.navigateBack(url, options);
  }

  async presentCenterModal(component: ComponentRef, componentProps) {
    const modalOpts: ModalOptions = {
      component,
      componentProps: { componentProps },
      cssClass: 'center-modal',
      animated: true,
      showBackdrop: true,
    };
    const modal = await this.modalCtrl.create(modalOpts);
    await modal.present();
  }

  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

  // async presentCenterModal(component: ComponentRef, componentProps) {
  //   const modalOpts: ModalOptions = {
  //     component,
  //     componentProps: { componentProps },
  //     cssClass: 'center-modal',
  //     animated: true,
  //     showBackdrop: true,
  //   };
  //   const modal = await this.modalCtrl.create(modalOpts);
  //   await modal.present();
  //   modal.onDidDismiss().then((dismiss) => {
  //     console.log('DIsmiss', dismiss);
  //   });
  // }

  async showAlert(title, message, func?) {
    const alert = await this.createAlert(title, false, message, {
      text: 'Ok',
      handler: async () => {
        if (func) func();
      },
    });
    await alert.present();
  }

  async createAlert(
    header,
    backdropDismiss,
    message,
    buttonOptions1,
    buttonOptions2?
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons: !buttonOptions2
        ? [buttonOptions1]
        : [buttonOptions1, buttonOptions2],
    });
    return alert;
  }

  async createLoader(message): Promise<HTMLIonLoadingElement> {
    const loader = await this.loadingCtrl.create({
      message,
      // duration: 10000
    });
    return loader;
  }

  async createToast(
    message,
    showCloseButton = false,
    position = 'bottom' as 'top' | 'bottom' | 'middle',
    duration = 1200
  ): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({
      message,
      position: 'middle',
      duration,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          },
        },
      ],
    });
    return toast;
  }

  async getGooglePlaceAutoCompleteList(searchText, geolocation, country) {
    const service = new window['google'].maps.places.AutocompleteService();
    let pred;
    // var circle = new google.maps.Circle(
    //     {center: geolocation, radius: 10000});
    // autocomplete.setBounds(circle.getBounds());
    await new Promise((resolve, reject) => {
      service.getPlacePredictions(
        {
          input: searchText,
          componentRestrictions: {
            country: country,
          },
        },
        (predictions) => {
          pred = predictions;
          resolve(true);
        }
      );
    });
    return pred;
  }

  async createActionSheet(button1, button2?, button3?) {
    const sheet = await this.actionSheetCtrl.create({
      buttons: [button1, button2, button3],
    });

    return sheet;
  }

  latLngConverterSQL(locations) {
    return locations.map((location) => {
      location.origin = { lat: location.origin_lat, lng: location.origin_lng };
      location.destination = {
        lat: location.destination_lat,
        lng: location.destination_lng,
      };
      return location;
    });
  }

  async getGeoCodedAddress(lat: number, lng: number) {
    let block, street, building, country;
    if (navigator.geolocation) {
      const geocoder = await new google.maps.Geocoder();
      const latlng = await new google.maps.LatLng(lat, lng);
      const request = { latLng: latlng };

      await new Promise((resolve, reject) => {
        geocoder.geocode(request, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const result = results[0];
            const rsltAdrComponent = result.address_components;
            if (result !== null) {
              if (rsltAdrComponent[0] !== null) {
                block = rsltAdrComponent[0].long_name;
                street = rsltAdrComponent[2].short_name;
                building = rsltAdrComponent[1].short_name;
              }
              // Find out country of geolocation
              // console.log(rsltAdrComponent);
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              let local_add_1 = '';
              let local_add_2 = '';
              for (let i = 0; i < rsltAdrComponent.length; i++) {
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes('country')
                ) {
                  country = rsltAdrComponent[i].short_name;
                }
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes(
                    'administrative_area_level_1'
                  )
                ) {
                  local_add_1 = rsltAdrComponent[i].short_name;
                }
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes('locality')
                ) {
                  local_add_2 = rsltAdrComponent[i].short_name;
                }
              }
              // this.userProvider.getUserData().location = local_add_1 + ', ' + local_add_2;
              resolve(true);
              // console.log('block resolved');
            } else {
              alert('No address available!');
            }
          }
        });
      });
    }
    return { block, street, building, country };
  }

  async showLoading(
    id: string = '',
    message: string = '',
    duration: number = 2500
  ) {
    try {
      if (this.loading) {
        this.loading.dismiss().then(async () => {
          this.loading = await this.loadingCtrl.create({
            duration: duration,
            message: message,
          });
          await this.loading.present();
        });
      }
      {
        this.loading = await this.loadingCtrl.create({
          duration: duration,
          message: message,
        });
        await this.loading.present();
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  async hideLoading() {
    setTimeout(() => {
      if (this.loading) this.loading.dismiss();
    }, 100);
    return;
  }

  async handleError(error): Promise<void> {
    console.log(
      'loader err >>>>>>>>>>>>>>',
      error.message.replace('Firebase:', '').trim()
    );
  }

  async handleAuthError(error): Promise<void> {
    console.log(
      'loader err >>>>>>>>>>>>>>',
      error.message.replace('Firebase:', '').trim()
    );
    //  console.log(error.message);
    const msg =
      error.message.indexOf('in-use') >= 0
        ? 'Email already in use'
        : error.message.indexOf('not-found') >= 0
        ? 'User not found'
        : 'Wrong email or password';

    this.showMsg(msg);
  }

  async showMsg(msg: string) {
    const toast = await this.createToast(msg, false, 'middle');
    await toast.present();
  }

  async showLoading2(message: string) {
    this.loading2 = await this.loadingCtrl.create({
      message,
    });
    await this.loading2.present();
  }

  async hideLoading2() {
    setTimeout(() => {
      if (this.loading2) this.loading2.dismiss();
    }, 100);
    return;
  }

  reverseAddressSearch(address: string) {
    console.log('address -> ', address);
    const geocoder = new google.maps.Geocoder();
    return new Promise<any>((resolve, reject) => {
      geocoder.geocode({ address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            results = results[0];
            if (results && !results.err) {
              const rsltAdrComponent = results.address_components;
              let number;
              let street;
              let city;
              let state;
              let zipcode;

              for (let i = 0; i < rsltAdrComponent.length; i++) {
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes('street_number')
                ) {
                  number = rsltAdrComponent[i].short_name;
                }
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes('route')
                ) {
                  street = rsltAdrComponent[i].short_name;
                }
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes('locality')
                ) {
                  city = rsltAdrComponent[i].short_name;
                }
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes('postal_code')
                ) {
                  zipcode = rsltAdrComponent[i].short_name;
                }
                if (
                  rsltAdrComponent[i].types &&
                  rsltAdrComponent[i].types.includes(
                    'administrative_area_level_1'
                  )
                ) {
                  state = rsltAdrComponent[i].short_name;
                }
              }
              resolve({ number, street, city, state, zipcode });
            }
          }
        } else {
          resolve('Error reversing location results');
        }
      });
    });
  }
}
