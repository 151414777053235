import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SceService } from '../sce.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private sce: SceService, private router: Router) {}

  canActivate():
    | boolean
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    if (
      this.sce?._loggedInUser?.id &&
      (this.sce?._loggedInUser?.onboardingCompleted ||
        this.sce?._loggedInUser?.squareCompleted)
    ) {
      return true;
    } else if (!this.sce?._loggedInUser?.onboardingCompleted) {
      this.router.navigate(['/dashboard']);
    } else if (!this.sce?._loggedInUser?.squareCompleted) {
      this.router.navigate(['/onboarding/square']);
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
