import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'my-menu',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/my-menu/my-menu.module').then((m) => m.MyMenuPageModule),
  },
  {
    path: 'chef-guide',
    loadChildren: () =>
      import('./pages/chef-guide/chef-guide.module').then(
        (m) => m.ChefGuidePageModule
      ),
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./pages/messages/messages.module').then(
        (m) => m.MessagesPageModule
      ),
  },
  {
    path: 'chef-social',
    loadChildren: () =>
      import('./pages/chef-social/chef-social.module').then(
        (m) => m.ChefSocialPageModule
      ),
  },

  {
    path: 'menu-builder',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./component/menu-theme/menu-theme.module').then(
        (m) => m.MenuThemeModule
      ),
  },

  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'my-orders',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/my-orders/my-orders.module').then(
        (m) => m.MyOrdersPageModule
      ),
  },

  {
    path: 'chef-landing',
    loadChildren: () =>
      import('./pages/cheflanding/cheflanding.module').then(
        (m) => m.CheflandingModule
      ),
  },
  {
    path: 'template',
    loadChildren: () =>
      import('./template/template.module').then((m) => m.TemplatePageModule),
  },

  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
