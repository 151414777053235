import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { UserCredential } from 'src/app/models/user';
import { ModalController, ModalOptions } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MustMatch } from 'src/app/_helps/must-match.validator';
import { MsgModalComponent } from '../modal/msg-modal/msg-modal.component';
import { SceService } from 'src/app/services/sce.service';
import { RideService } from 'src/app/services/ride/ride.service';
import { UtilService } from 'src/app/services/util/util.service';
import { ApiService } from 'src/app/services/api/api.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss'],
})
export class AuthFormComponent {
  authForm: FormGroup;
  authSigninForm: FormGroup;
  @Input() actionButtonText: string;
  @Input() isPasswordResetPage = false;
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() resetFormSubmitted = new EventEmitter<any>();

  selectedCuisine: any[] = [];
  cuisine = '';
  cuisineCtrl: FormControl;
  filteredcuisine: Observable<any[]>;
  _showPassword = false;
  _cuisineTouched = false;
  phone = '';
  _isFormClicked = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private rideService: RideService,
    public sce: SceService,
    private util: UtilService,
    private api: ApiService,
    private fireAuth: AngularFireAuth
  ) {
    this.authSigninForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      acceptTerms: [true, Validators.requiredTrue],
    });

    this.authForm = this.formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.minLength(2)]],
        lastName: [''],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        acceptTerms: [true, Validators.requiredTrue],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.cuisineCtrl = new FormControl();
  }

  async ngOnInit() {
    this._cuisineTouched = false;
    if (this.rideService.cuisine_list.length === 0) {
      //this.util.showLoading('loadCuisine', '');
      try {
        const cuisines = await this.rideService.getACollectionData(
          'categories'
        );
        //this.util.hideLoading();
        const l = cuisines.filter((el: any) => el.cuisine);
        this.rideService.cuisine_list = l.map((el: any) => el.name);
        console.log(
          'cuisine_list filtered ->',
          cuisines,
          this.rideService.cuisine_list
        );

        this.filteredcuisine = this.cuisineCtrl.valueChanges.pipe(
          startWith(''),
          map((cuisine) => {
            console.log('cuisine ->', cuisine);
            return cuisine
              ? this.filterCuisine(cuisine)
              : this.rideService.cuisine_list.slice();
          })
        );
      } catch (e) {
        //this.util.hideLoading();
      }
    } else {
      this.filteredcuisine = this.cuisineCtrl.valueChanges.pipe(
        startWith(''),
        map((cuisine) => {
          console.log('cuisine ->', cuisine);
          return cuisine
            ? this.filterCuisine(cuisine)
            : this.rideService.cuisine_list.slice();
        })
      );
    }
  }

  get f() {
    return this.authForm.controls;
  }

  formatPhone(e: any) {
    const x = e.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phone = !x[2]
      ? x[1]
      : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    this.authForm.controls['phone'].setValue(phone);
  }

  filterCuisine(name: string) {
    const list = this.rideService.cuisine_list.filter(
      (cuisine) => cuisine.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
    const arr = list.filter((el) => {
      if (this.selectedCuisine.indexOf(el) === -1) {
        return el;
      }
    });
    return arr.length ? arr : ['No Item found'];
  }

  selectCuisine(e: any) {
    const str = e.target.outerText.trim();
    if (!this.selectedCuisine.includes(str) && this.selectedCuisine.length < 5)
      this.selectedCuisine.push(str);
    this.cuisine = '';
  }

  delCuisine(idx: number) {
    this.selectedCuisine.splice(idx, 1);
  }

  async submitCredentials(authForm: FormGroup) {
    this._isFormClicked = true;
    if (!authForm.valid) return;

    const credentials: UserCredential = {
      firstName: authForm.value.firstName,
      lastName: authForm.value.lastName?? '',
      phone: '',
      email: authForm.value.email.toLowerCase(),
      password: authForm.value.password,
      zipCode: '',
      cuisine: this.selectedCuisine,
      auth: {},
    };

    if (!this.sce._isLogin && credentials.email) {
      // check if email exist
      const res = await this.fireAuth.fetchSignInMethodsForEmail(
        credentials.email.toLowerCase()
      );
      if (res && res.length > 0) {
        console.log('1 email already exists');
        this.util.showMsg('Email already exists, please use a different one.');
      } else {
        const modalOpts: ModalOptions = {
          component: MsgModalComponent,
          componentProps: {
            phone: credentials.phone,
            email: credentials.email.toLowerCase(),
            name: credentials.firstName + ' ' + credentials.lastName,
          },
          cssClass: 'center-modal',
          animated: true,
        };
        const modal = await this.modalCtrl.create(modalOpts);
        await modal.present();
        modal.onDidDismiss().then((res: any) => {
          console.log(res);

          // avoid phone verif
          if (res && res?.data) res.data.phoneVerified = true;

          if (res.data && res.data.phoneVerified && res.data.emailVerified) {
            credentials.auth = res.data;
            console.log(credentials);
            this.formSubmitted.emit(credentials);
          }
        });
      }
    } else if (this.sce._isLogin && credentials.email && credentials.password) {
      console.log('Form ->', authForm.value);
      this.formSubmitted.emit(credentials);
    }
  }

  forgotPass() {
    this.sce.openResetModal();
  }

  resetPass(authForm: FormGroup) {
    this.resetFormSubmitted.emit({
      email: authForm.value.email.toLowerCase(),
    });
  }
}
