import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from './api/api.service';
import { UtilService } from './util/util.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { profilePreviewComponent } from '../component/modal/profile-preview/prev-modal.component';
import { PrintLabelComponent } from '../component/modal/print-label/print-label.component';
import { ResetPasswordPage } from '../pages/reset-password/reset-password.page';

export interface vendorHubt {
  state: string;
  zone: number;
  zoneId: string;
  hub: number;
  hubAddress: string;
  hubs: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class SceService {
  isAdmin = true;
  _user: any;
  _email: string;
  _loggedInUser: any;
  _session = null;
  signInToken;
  currentRideId = '';

  data: any = {
    api_key: '',
    categories: [],
    meals: [],
    ingredients: [],
    vendors: [],
  };

  vendorHub: vendorHubt = {
    state: '',
    zone: null,
    zoneId: '',
    hub: null,
    hubAddress: '',
    hubs: [],
  };
  selectedVendor;
  _editItem = false;
  selectedVendorIndex;

  _showForm = false;
  _isLogin = true;
  _step = 1;
  onboardingCompleted = false;
  squareCompleted = false;
  myStep = 1;

  totalSteps = 7;
  questions: any[] = [];
  notificationList1: any[] = [];
  notificationList2: any[] = [];
  menuActions: any = [];
  menuCustom: any = [];
  templatesList: any = [];
  _editVendor = false;
  _showMenuTheme = false;
  _isGetStarted = false;
  activeMenu: any[] = [];

  _theme: number;
  _style: string;

  reHeatInstructions = [
    `
Reheating Vegetables -You will need to reheat them on low heat, keeping the oven at around 200°-250° to not burn them or heat them too fast to a mush.
    `,
    `
Microwave Instructions – It is recommended to reheat food on 60% power to avoid drying out food. Stir food after a minute or two to ensure even reheating
    `,
    `
Take & Bake Cookies- Bake at 350° for 7-10 minutes, rotate pan, bake another 7-10 minutes or until lightly golden brown. 
    `,
  ];

  tangyContacts = {
    phone: '...........',
    email: 'chefsupport@tangychef.com',
    guidelinesLink: 'https://tangychef.com',
    instructionsLink: 'https://tangychef.com',
    infoLink: 'https://tangychef.com',
    chefFaq: 'https://tangychef.com/chefs-faqs/',
    cuisine: 'https://tangychef.com/cuisines-more/',
    faqs: 'https://tangychef.com/faqs/',
    foodSafety: 'https://tangychef.com/food-safety/',
    aboutUs: 'https://tangychef.com/about-us/',
    blog: 'https://tangychef.com/blog/',
    career: 'https://tangychef.com/careers/',
    dropLocations: ['123, Irvine blvd, Irvine, CA 92616'],
  };

  chefLanding = false;
  constructor(
    public loadingCtrl: LoadingController,
    private apiService: ApiService,
    private util: UtilService,
    private modalController: ModalController
  ) {
    this.signInToken = Math.round(100000000000 * Math.random()).toString();
  }

  //-----------------------------------------

  async takePicture(): Promise<any> {
    return await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
    });
  }

  logs(log: string, data: any) {
    console.log(log, data);
  }

  findZoneHubs() {
    this.vendorHub = {
      state: this._loggedInUser.addressState
        ? this._loggedInUser.addressState
        : '',
      zone: this._loggedInUser.zone ? this._loggedInUser.zone : null,
      zoneId: this._loggedInUser.addressState ? this._loggedInUser.zoneId : '',
      hub: this._loggedInUser.hub ? this._loggedInUser.hub : null,
      hubAddress: this._loggedInUser.hubAddress
        ? this._loggedInUser.hubAddress
        : '',
      hubs: [],
    };
    console.log('=====>', this.vendorHub);
    if (this.data.zone && this.data.zone.length > 0) {
      this.data.zone.forEach((el) => {
        el.states.forEach((st) => {
          if (this.vendorHub.state.toLowerCase() === st.toLowerCase()) {
            console.log('el =>', el);
            this.vendorHub = {
              state: this.vendorHub.state ? this.vendorHub.state : '',
              zone: el.zone,
              zoneId: el.id,
              hub: this.vendorHub.hub ? this.vendorHub.hub : null,
              hubAddress: this.vendorHub.hubAddress
                ? this.vendorHub.hubAddress
                : '',
              hubs: el.hubs,
            };
          }
        });
      });
    }
    console.log('vendor hub -->', this.vendorHub);
  }

  getAllMealData() {
    this.util.showLoading('loadAllData', '');
    return new Promise<any>((resolved, rejected) => {
      return combineLatest([
        this.apiService.getOrderedList('categories'),
        this.apiService.getListByVendorId('meals', this._loggedInUser.id),
        this.apiService.getOrderedList('ingredients'),
        this.apiService.getZoneList('zone'),
      ]).subscribe(
        (res: any) => {
          this.data = {
            categories: res[0],
            meals: res[1],
            ingredients: res[2],
            zone: res[3],
          };
          this.util.hideLoading();
          this.findZoneHubs();
          console.log('all data =->', this.data);
          resolved(true);
        },
        (err) => {
          this.util.hideLoading();
          console.log('err get all meal data ->', err);
        }
      );
    });
  }

  /**
   *
   * @param index
   * @param asUser
   */
  async openModalProfilepreview(index: number, asUser) {
    const modal = await this.modalController.create({
      component: profilePreviewComponent,
      componentProps: { index: index, asUser },
      cssClass: 'my-modal-class',
    });
    modal.onDidDismiss().then((res) => {
      if (res && res.data > -1) {
        this._loggedInUser.template = res.data;
        this.apiService
          .updateDataApi('vendors', this._loggedInUser.id, {
            template: res.data,
          })
          .subscribe(
            (res) => {
              this.util.showMsg('Template saved successfully');
              console.log('saved ->', this._loggedInUser?.template);
            },
            (err) => {
              this.util.showMsg('Template not saved');
              console.log('not saved');
            }
          );
      }
    });

    modal.present();
    await modal.onWillDismiss();
    this._showMenuTheme = false;
  }

  async openResetModal() {
    const modal = await this.modalController.create({
      component: ResetPasswordPage,
      componentProps: {},
      cssClass: 'center-modal',
    });
    return await modal.present();
  }

  async openModalPrintLabel(mealName: string, meals: any, day: string) {
    const modal = await this.modalController.create({
      component: PrintLabelComponent,
      componentProps: { mealName, meals, day },
      cssClass: 'my-modal-class',
    });
    modal.onDidDismiss().then((res) => {
      console.log(res);
    });

    return await modal.present();
  }

  publish() {
    this.apiService
      .updateUser(this._loggedInUser.id, {
        active: this._loggedInUser.active,
      })
      .subscribe(async (el) => {
        console.log('vend status updated');
      });
  }

  initOnboarding() {
    this._loggedInUser = {};
    this._session = null;
    this.currentRideId = '';
    this.data = {
      categories: [],
      meals: [],
      ingredients: [],
      vendors: [],
    };

    this.vendorHub = {
      state: '',
      zone: null,
      zoneId: '',
      hub: null,
      hubAddress: '',
      hubs: [],
    };

    this._editItem = false;
    this._showForm = false;
    // this._isLogin = true;
    this.onboardingCompleted = false;
    this.myStep = 1;
    this.totalSteps = 7;
    this.notificationList1 = [];
    this.notificationList2 = [];
    this.menuActions = {};
    this.menuCustom = [];
    this._editVendor = false;
    this._showMenuTheme = false;
    this._isGetStarted = false;
    this.activeMenu = [];
    this.initQuestions();
  }

  initQuestions() {
    this.questions = [
      {
        num: 0,
        question: 'How did you hear about Tangy Chef?',
        answer: [
          { name: 'Facebook Post', selected: false },
          { name: 'Friends or Family', selected: false },
          { name: 'Facebook Ad', selected: false },
          { name: 'Job Board', selected: false },
          { name: 'Instagram Ad', selected: false },
          { name: 'Mailer or Postcard', selected: false },
          { name: 'Instagram feed', selected: false },
          { name: 'Nextdoor', selected: false },
          { name: 'Other', selected: false, value: '' },
        ],
      },
      {
        num: 1,
        question:
          'Do you have any experience running a food business or cooking professionally?',
        answer: [
          {
            name: 'Yes, I am currently running my own independent food business (e.g., a food truck, pop-up, catering, meal prep, private chef, WhatsApp / Instagram-based, or small commercial kitchen-based food business) and have weekly customers who purchase from me.',
            selected: false,
          },
          {
            name: 'I used to run my own independent food business in the past (e.g., 6+ months ago), but I do not currently run any independent food business.',
            selected: false,
          },
          {
            name: 'I have some culinary training (e.g., I work/worked in a restaurant or went to culinary school), but I have never run my own independent food business before.',
            selected: false,
          },
          {
            name: 'No, I have had no prior food business or professional cooking experience (I have only ever cooked for my family or friends in my community).',
            selected: false,
          },
        ],
      },
      {
        num: 2,
        question:
          'What is your desired 1-2 year end goal of cooking on Tangy Chef?',
        answer: [
          {
            name: 'I want to cook 4-5+ days per week, as a full-time cook on Chef or owner of my own food business, providing the main source of income for me / my family.',
            selected: false,
          },
          {
            name: "I want to cook 1-3 days / week, as a part-time side gig supporting my / my family's income with some extra cash (but not as a main source of income).",
            selected: false,
          },
          {
            name: 'I want to only cook as a fun hobby, to share food with my community (I am not concerned about how much earnings I can make).',
            selected: false,
          },
        ],
      },

      {
        num: 3,
        question:
          'To help us best customize your experience on Tangy Chef - could you share your experience with us?',
        answer: [
          {
            name: ' I run my own medium-sized food business and have many regular customers!',
            selected: false,
          },
          {
            name: 'I run my own small-sized food business and have a few regular customers!',
            selected: false,
          },
          {
            name: 'I used to run my own food business, but not currently.',
            selected: false,
          },
          {
            name: "I don't have prior business experience selling my food, but I'm excited to get started!",
            selected: false,
          },
        ],
      },
      {
        num: 4,
        question:
          'Would you like a Tangy Chef driver to pick up your order and deliver it to a Hub for a fee (4.99/per pickup)',
        answer: [
          {
            name: 'Everytime',
            selected: false,
          },
          {
            name: 'Sometimes',
            selected: true,
          },
          {
            name: 'Never',
            selected: false,
          },
        ],
      },
      {
        num: 5,
        question: 'Pick my order today',
        answer: [
          {
            name: 'Yes Please',
            selected: false,
          },
        ],
      },

      {
        num: 6,
        question: 'Please choose your lead delivery time',
        answer: [
          {
            name: '0 day: As soon as possible',
            selected: false,
          },
          {
            name: 'Less than 1 day',
            selected: false,
          },
          {
            name: '1 day',
            selected: false,
          },
          {
            name: '2 days',
            selected: true,
          },
        ],
      },
    ];
  }
  initMenuActions() {
    this.menuActions = {
      question: 'Actions to make your menu stand out',
      answer: [
        {
          name: 'Add at least 10 main items.',
          selected: false,
        },
        {
          name: 'Add at least 5 side items.',
          selected: false,
        },
        {
          name: 'Add at least 3 vegetarian items.',
          selected: false,
        },
        {
          name: 'Have main dishes be at least 16 oz.',
          selected: false,
        },
      ],
    };
  }

  /**------------------------------------
   *
   * @returns
   */
  async initMenuItems(): Promise<any> {
    this.menuCustom = [];
    return new Promise<any>(async (resolved, rejected) => {
      try {
        const list = await this.apiService.findAll(
          'optionsModifiers',
          (ref: any) => ref.orderBy('createdAt', 'desc')
        );

        console.log('xyz menu customisation --=-=->', list);

        if (list && list.length > 0) {
          this.menuCustom = list;
          resolved(list);
        } else {
          resolved([]);
        }
      } catch (e) {
        resolved([]);
        console.log('error getting menu items ->', e);
      }
    });
  }

  /**------------------------------
   *
   * @returns
   */
  async getTemplates(): Promise<any> {
    this.templatesList = [];
    return new Promise<any>(async (resolved, rejected) => {
      try {
        const list = await this.apiService.findAll(
          'menuTemplates',
          (ref: any) => ref.orderBy('createdAt', 'desc')
        );

        console.log('xyz menu templates --=-=->', list);

        if (list && list.length > 0) {
          this.templatesList = list;
          resolved(list);
        } else {
          resolved([]);
        }
      } catch (e) {
        resolved([]);
        console.log('error getting menu items ->', e);
      }
    });
  }
}
