export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCuFmnjPX9Sp3xERks3rQfTQjIYNUoercM',
    authDomain: 'tangy-chef.firebaseapp.com',
    projectId: 'tangy-chef',
    storageBucket: 'tangy-chef.firebasestorage.app',
    messagingSenderId: '211680630603',
    appId: '1:211680630603:web:00b843408023039ecdbace',
    measurementId: 'G-2W62FTQ3DC',
  },

  tangyApiKey: 'f488c628a5fdf8b17861bb8d4947a5b3eb00d2f8',
  stripe: {
    publishable:
      'pk_test_51H1tr1E38vCdwW7OLxLMDG7TxM1Op8SW1Dqmapf3MlAYjKyucFIm9Pu8A4stnxLrl3L7Sqec1CDfXByhHmHYnDQ200xBmRv7T9',
    secret:
      'sk_test_51H1tr1E38vCdwW7Ozum3tgXnl4GkueeJLfpGsZNgkOk2ykrvnlg15Q9FEZM5wIKQBTtZqM0RqtPm58GZW14y0Vz300P20D4JXR',
  },
  UPDATE_PAYMENT_METHOD: 'Please update your Payment Method',
  DELETE_CARD: 'Please Confirm Deleting this item...',
  COUNTRY: 'US',

  URL_BASE: 'https://app.qa.tangychef.com/',
  tangyApiBase: 'https://tangy-api-e87fcd0feaa8.herokuapp.com/api/',
  squareBaseUrl: 'https://tangy-api-e87fcd0feaa8.herokuapp.com/api/square/',
  tangyVerifEmailURL: 'https://app.qa.tangychef.com/',

  theBlueCart: {
    JWTKey:
      'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJOU1RfS0VZIjoiNnY5eSRCJkUpSEBNY1FmVGpXblpyNHU3dyF6JUMqRi1KYU5kUmdVa1hwMnM1djh5L0E_RChHK0tiUGVTaFZtWSIsIm5hbWUiOiJWYWRpbSJ9.qhx5TbhInLNLPSS9bFWdzt-T_XXdMZCjLBgzdXmD3LiHpcI7Luv_H1ynFnL3ARZY5pcB58kSU6Z6o9ZzDTP5Sw',
  },
};
