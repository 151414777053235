import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirestoreService } from '../firestore/firestore.service';
import { AuthenticationService } from './firebase-authentication.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  id;
  constructor(
    public firestore: FirestoreService,
    private auth: AuthenticationService,
    private http: HttpClient
  ) {}

  logIn(username: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.auth
        .login(username, password)
        .then((user) => {
          observer.next({ id: user.uid, token: user.ya });
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  // checkEmailExist(email) {
  //   this.firestore.fetchSignInMethodsForEmail(email).then(user => {
  //     console.log("User already in use");
  //   }).catch(err => {
  //     if (err.code === 'auth/user-not-found') {
  //       this.regEmail = email;
  //     }
  //   })

  /**----------------------------------------------------------------------- send email
   *
   * @param data
   * @returns
   */
  sendTangyEmail(data: any) {
    return new Promise<any>((resolved, rejected) => {
      if (!data.email) {
        rejected('Wrong Email, please refresh browser');
      }
      const url = environment.tangyApiBase + 'sendEmail';
      console.log('send email ->', data, url);
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.tangyApiKey,
        }),
      };

      this.http.post<any>(url, data, httpOptions).subscribe(resolved, rejected);
    });
  }

  /**----------------------------------------------------------------------- send email
   *
   * @param data
   * @returns
   */
  sendWelcEmail(endPoint: string, data: any) {
    return new Promise<any>((resolved, rejected) => {
      if (!data.email) {
        rejected('Wrong Email, please refresh browser');
      }
      const url = environment.tangyApiBase + endPoint;
      console.log('send email ->', data, url);
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.tangyApiKey,
        }),
      };

      this.http.post<any>(url, data, httpOptions).subscribe(resolved, rejected);
    });
  }

  /**-----------------------------------------------------------------------------------send SMS
   *
   * @param phoneNumber
   * @param sms
   * @returns
   */
  sendTangySMS(phoneNumber, message) {
    return new Promise<any>((resolved, rejected) => {
      if (!phoneNumber) {
        rejected('No phone number, please try again');
      }
      const url = environment.tangyApiBase + 'sendSMS';
      const data = {
        number: phoneNumber.replace(/-/g, ''),
        message,
      };
      console.log('send sms data', url, data);
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.tangyApiKey,
        }),
      };
      this.http.post<any>(url, data, httpOptions).subscribe(resolved, rejected);
    });
  }

  /**-------------------------------------------------
   *
   * @param data
   * @returns
   */
  sendCodeByEmail(data: any) {
    return new Promise<any>((resolved, rejected) => {
      if (!data.email) {
        rejected('Wrong Email, please refresh browser');
      }
      const url = environment.tangyApiBase + 'sendEmail';
      console.log('send email ->', data);
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.tangyApiKey,
        }),
      };

      this.http.post<any>(url, data, httpOptions).subscribe(resolved, rejected);
    });
  }

  /* eslint id-blacklist:off*/
  sendOtp(phoneNumber) {
    return new Promise<any>((resolved, rejected) => {
      if (!phoneNumber) {
        rejected('No phone number, please try again');
      }
      const url = environment.tangyApiBase + 'sendOTP';
      const data = {
        number: phoneNumber.replace(/-/g, ''),
      };
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.tangyApiKey,
        }),
      };
      this.http.post<any>(url, data, httpOptions).subscribe(resolved, rejected);
    });
  }

  /**--------------------------------------------------------------------------
   *
   * @param vendor
   * @returns
   */
  signUpVendor(vendor): Observable<any> {
    return new Observable((observer) => {
      this.auth
        .createAccount(vendor.email, vendor.password)
        .then((User) => {
          if (vendor.password) {
            delete vendor.password;
          }
          vendor.roll = 'vendor';
          vendor.id = User.uid;
          vendor.template = 1;

          console.log('create acount user -> ', vendor);
          this.firestore.createWithId('vendors', vendor).then(
            (usr) => {
              observer.next(vendor);
            },
            (err) => {
              observer.error(err);
            }
          );
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  old_loadOrders(vendorId, timeout, completed): Observable<any> {
    return this.firestore.find('orders', (ref) =>
      ref
        .where('request_timeout', '==', timeout)
        .where('request_completed', '==', completed)
        .where('order.resto_ids', 'array-contains', vendorId)
        .orderBy('createdAt', 'desc')
    );
  }

  loadOrders(vendorId): Observable<any> {
    // find all orders having vendorId
    return this.firestore.find('orders', (ref) =>
      ref
        .where('order.resto_ids', 'array-contains', vendorId)
        .orderBy('createdAt', 'desc')
    );
  }

  updateToken(id) {
    console.log('set user id -=-=>');
    this.id = id;
  }

  logout() {
    return this.auth.logout();
  }

  getUser(): Observable<any> {
    console.log('get user --->', this.id);
    return this.addIdToObject(
      this.id,
      this.firestore.getOne('vendors', this.id)
    );
  }

  updateUser(id, userData): Observable<any> {
    return from(this.firestore.update('vendors', id, userData));
  }

  getRestoItems(dbDoc: string, searchStr: string): Observable<any> {
    console.log('=>=>=>', dbDoc, searchStr);
    return this.firestore.find(dbDoc, (ref) =>
      ref.where('vendorId', '==', searchStr)
    );
  }

  addIdToObject(id, obj: Observable<any>) {
    return new Observable((observer) => {
      if (id) {
        obj.subscribe(
          (ref) => {
            const newObj = ref;
            if (newObj) {
              newObj.id = id;
            }
            observer.next(newObj);
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        observer.error({ message: 'No ID' });
      }
    });
  }

  getSingleRide(id): Observable<any> {
    return this.firestore.getOne('orders', id); // TODO
  }
  updateRideData(id, rideData): Observable<any> {
    // @@@@
    return from(this.firestore.update('orders', id, rideData));
  }

  updateDataApi(collection: string, id: string, data: any): Observable<any> {
    return from(this.firestore.update(collection, id, data));
  }

  saveDataApi(collection: string, data: any): Observable<any> {
    return from(this.firestore.create(collection, data));
  }

  delDataApi(collection: string, id: string): Observable<any> {
    return from(this.firestore.delete(collection, id));
  }

  getOrderedList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy('order', 'asc')
    );
  }

  getObsCollection(collection: string, id: string): Observable<any> {
    return this.addIdToObject(id, this.firestore.getOne(collection, id));
  }

  getPromiseCollection(collection: string, id: string) {
    return new Promise<any>((resolve, reject) => {
      this.addIdToObject(id, this.firestore.getOne(collection, id)).subscribe(
        resolve,
        reject
      );
    });
  }

  getZoneList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) => ref);
  }

  getVendorList(collection, search): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.where('roll', '==', search).orderBy('createdAt', 'desc')
    );
  }

  getUsersList(collection, search): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy('createdAt', 'desc')
    );
  }

  async findAll(collection: string, query?: any) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .find(collection, query ? query : null)
        .subscribe(resolve, reject);
    });
  }

  getList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy('createdAt', 'desc')
    );
  }

  getListByVendorId(collection, vendorId): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy('createdAt', 'desc').where('vendorId', '==', vendorId)
    );
  }

  postHttpData(endPoint: string, body: any): Observable<any[]> {
    const url = environment.tangyApiBase + endPoint;
    const httpOptions = {
      headers: new HttpHeaders({
        api_key: environment.tangyApiKey,
      }),
    };
    return this.http.post<any[]>(url, body, httpOptions).pipe();
  }

  // const date = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000); // x days

  /**------------------------------------------------------------
   *
   * @param data
   * @returns
   */
  saveCatalogToSquare(data: any) {
    const httpUrl = environment.squareBaseUrl + 'catalog';

    const httpOptions = {
      headers: new HttpHeaders({
        token: '4b4d8cd-7a2d-4a5f-a0e2-798021f1e294',
      }),
      params: {
        data,
      },
    };
    return this.http.post<any>(httpUrl, httpOptions);
  }

  /**----------------------------------------------------------obs
   *
   * @param endPoint
   * @returns
   */
  getSquareDataObs(endPoint: string): Observable<any> {
    const httpUrl = environment.squareBaseUrl + endPoint;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*',
      async: 'true',
      crossDomain: 'true',
      'Access-Control-Allow-Origin': '*',
      'cache-control': 'no-cache',
      api_key: environment.tangyApiKey,
    });
    return this.http.get(httpUrl, { headers: headers });
  }

  /****************************************************************
   *
   * @param data
   * @returns
   */
  getSquareData(dt: any, myKey: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpUrl = environment.squareBaseUrl;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          async: 'true',
          crossDomain: 'true',
          'Access-Control-Allow-Origin': '*',
          'cache-control': 'no-cache',
          api_key: myKey,
        }),
        params: dt,
      };
      this.http.post<any>(httpUrl, dt, httpOptions).subscribe({
        next(res: any) {
          resolve(res);
        },
        error(e: any) {
          reject(e);
        },
      });
    });
  }

  /****************************************************************
   * vendor onboarding
   * @param data
   * @returns
   */
  postSquareData(endPoint: any, data: any, myKey: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpUrl = environment.squareBaseUrl;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          async: 'true',
          crossDomain: 'true',
          'Access-Control-Allow-Origin': '*',
          'cache-control': 'no-cache',
          api_key: myKey,
        }),
      };

      this.http
        .post<any>(httpUrl, data, httpOptions)
        .subscribe(resolve, reject);
    });
  }

  /**----------------------------------------------------------------------- send email
   *
   * @param data
   * @returns
   */
  sendActivationLink(endPoint: string, data: any) {
    return new Promise<any>((resolved, rejected) => {
      if (!data.email) {
        rejected('Wrong Email, please refresh browser');
      }
      const url = environment.tangyApiBase + endPoint;
      console.log('send email ->', data, url);
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.tangyApiKey,
        }),
      };

      this.http.post<any>(url, data, httpOptions).subscribe(resolved, rejected);
    });
  }

  /**---------------------------------------
   * @param collection
   * @param id
   * @param data
   * @returns
   */
  updateOne(collection, id, data) {
    return new Promise(async (resolve, reject) => {
      from(this.firestore.update(collection, id, data)).subscribe(
        resolve,
        reject
      );
    });
  }

  /**----------------------------------------
   *
   * @param collection
   * @param id
   * @returns
   */
  getOneRec(collection: string, id: string) {
    return new Promise<any>((resolve, reject) => {
      this.addIdToObject(id, this.firestore.getOne(collection, id)).subscribe(
        resolve,
        reject
      );
    });
  }
}
