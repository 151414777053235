import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';

@Injectable()
export class StorageService {
  constructor(
    private storage: AngularFireStorage,
    public baseFs: FirestoreService,
    private util: UtilService
  ) { }

  public uploadContent(file: any, fileName: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (file) {
          this.util.showLoading('uploadFile', 'Uploading File...')
          return this.storage
            .upload(`uploads/${fileName}`, file)
            .then(
              (success) => {
                return this.storage
                  .ref(`uploads/${fileName}`)
                  .getDownloadURL()
                  .subscribe((url) => {
                    return this.baseFs
                      .uploadFile('uploads', url, fileName)
                      .then(() => {
                        this.util.hideLoading()
                        resolve({ url, fileName });
                      })
                      .catch((err) => {
                        this.util.hideLoading()
                        reject(err);
                      });
                  });
              },
              (failure) => {
                this.util.hideLoading()
                reject(failure);
              }
            )
            .catch((err) => {
              this.util.hideLoading()
              reject(err);
            });
        } else {
          this.util.hideLoading()
          reject(new Error(' choice key not given'));
        }
      } catch (e) {
        this.util.hideLoading()
        reject(e);
      }
    });
  }
}
