import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FCM } from '@capacitor-community/fcm';
import { InitUserProvider } from '../inituser/inituser.service';
import { SceService } from '../sce.service';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  _registered = false;
  msg = 'Off';
  constructor(
    private platform: Platform,
    private userProvider: InitUserProvider,
    private sce: SceService,
    private afs: AngularFirestore,
    private util: UtilService
  ) { }

  ngOnInit() { }

  requestPermission() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              this.util.showMsg('Push Notification permission denied');
            } else {
              this.util.showMsg('Push Notification permission granted');
              this.register();
            }
          });
        } else {
          this.register();
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        console.log('listen to reg');
        this.saveTokenToFire(token.value);
        this.util.showMsg('Device registered to receive notification');
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        this.util.showMsg(
          'Device not registered for notification, please try again'
        );
      });
    } else {
      console.log('fcm web.........');
    }
  }

  register() {
    console.log('registering..........');
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register()
      .then((res) => {
        console.log('registered -------> ', res);

        FCM.subscribeTo({ topic: 'dailyMealsNotif' })
          .then((r) => console.log(`subscribed to topic`))
          .catch((err) => console.log('fcm err -> ', err));
      })
      .catch((err) => console.log('reg catch err -> ', JSON.stringify(err)));
  }

  private saveTokenToFire(token: string): any {
    console.log('saveTokenToFire -> ', token);

    if (token) {
      console.log('saveTokenToFire 2 -> ', token);
      const deviceRef = this.afs.collection('devices');
      const docData = {
        id: this.sce._loggedInUser.id,
        token,
        vendorId: this.sce._loggedInUser.id,
      };
      deviceRef
        .doc(token)
        .set(docData)
        .then((res) => {
          this._registered = true;
          this.msg = 'On';
          this.listenToNotif();
        })
        .catch((err) => {
          this._registered = false;
          this.msg = 'Off';
        });
    }
  }

  listenToNotif() {
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );
  }

}
