<div class="scroll" style="width: 100%; margin: 1rem auto">
  <ion-row
    class="border-bottom flex-col"
    *ngFor="let order of orderNumbers; let i = index"
  >
    <!-------------------label for chef------------------->

    <div class="qr-1">
      <ion-row class="border-bot">
        <ion-col>
          <img
            src="assets/images/logo.png"
            class="logo"
            style="max-width: 120px !important"
          />
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="8">
          <ion-row class="margin-top-3">
            <ion-col size="5" class="bold-600">Hub Address: </ion-col>
            <ion-col class="f-regular">
              <span class="bold-600">{{ order.zone.hubAddress }}</span>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="5" class="bold-600"> Prepared by: </ion-col>
            <ion-col class="f-regular">
              <span class="bold-600">{{ order.vendorName }}</span>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="5" class="bold-600">Date: </ion-col>
            <ion-col>
              <span>{{
                (day == "today" ? todayDate : tomorrowDate)
                  | date : "EEE, MMM d, y"
              }}</span>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col size="4" class="center">
          <qrcode
            [qrdata]="getOrderLink(order)"
            [allowEmptyString]="true"
            [ariaLabel]="order?.orderId"
            [cssClass]="'center'"
            [colorDark]="'#000000ff'"
            [colorLight]="'#ffffffff'"
            [elementType]="'canvas'"
            [errorCorrectionLevel]="'M'"
            [imageSrc]="'assets/icon/qrlogo.png'"
            [imageHeight]="60"
            [imageWidth]="60"
            [margin]="0"
            [scale]="1"
            [title]="p?.name"
            [width]="180"
          ></qrcode>
          <div class="f-regular">
            Order # &nbsp;
            <span class="bold-500">{{ order.orderId }}</span>
          </div>
        </ion-col>
      </ion-row>
    </div>

    <section *ngFor="let p of labels">
      <div class="qr-2" *ngIf="p.order_id == order.order_id">
        <!------------------------------------->
        <ion-row class="border-bot">
          <ion-col size="5" class="flex-row-center-2">
            <img
              src="assets/images/logo.png"
              class="logo"
              style="max-width: 120px !important"
            />
          </ion-col>
          <ion-col class="flex-col">
            <div class="bold-600 f-regular">{{ p.name }}</div>
            <div class="f-regular">
              Prepared by
              {{ p.vendorName }}
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="8">
            <ion-row>
              <ion-col size="4" class="bold-600"> Reheating: </ion-col>
              <ion-col class="f-regular">
                {{
                  sce.reHeatInstructions[p?.reHeatIndex]
                    ? sce.reHeatInstructions[p?.reHeatIndex]
                    : "..."
                }}
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="4" class="bold-600"> Fresh Unit: </ion-col>
              <ion-col>
                <span>{{
                  (day == "today" ? todayDate : tomorrowDate)
                    | date : "EEE, MMM d, y"
                }}</span>
              </ion-col>
            </ion-row>

            <div class="dietary-tags margin-top-2">
              <section *ngFor="let d of p?.tagsList">
                <img
                  *ngIf="d.selected"
                  class="dietary"
                  [src]="'assets/images/' + d.icon"
                />
              </section>

              <div class="flex-row-center-2" *ngIf="p?.spiceLevel > -1">
                <img src="assets/images/chil.png" /> {{ p?.spiceLevel }}/5
              </div>
            </div>
          </ion-col>

          <ion-col size="4">
            <div class="center">
              <qrcode
                [qrdata]="getDishLink(p)"
                [allowEmptyString]="true"
                [ariaLabel]="p?.name"
                [cssClass]="'center'"
                [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'"
                [elementType]="'canvas'"
                [errorCorrectionLevel]="'M'"
                [imageSrc]="'assets/icon/qrlogo.png'"
                [imageHeight]="60"
                [imageWidth]="60"
                [margin]="0"
                [scale]="1"
                [title]="p?.name"
                [width]="180"
              ></qrcode>
            </div>
            <div class="flex dash">
              <span>Order</span>
              <span>#</span>
              <span class="bold-500">{{ p?.orderId }}</span>
            </div>
            <div class="flex dash">
              <span>Item</span> <span>#</span>
              <span class="bold-500">{{ p?.itemId }}</span>
            </div>
          </ion-col>
        </ion-row>

        <!-------------------------------->
      </div>
    </section>
  </ion-row>
</div>
