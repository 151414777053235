<ion-app>
  <ion-split-pane
    [disabled]="!sce.chefLanding && sce?._loggedInUser?.id ? false : true"
    contentId="main-content"
  >
    <ion-menu
      contentId="main-content"
      type="overlay"
      side="start"
      class="left-menu"
      [maxEdgeStart]="0"
    >
      <ion-content>
        <div class="center">
          <a href="https://tangychef.com">
            <img
              id="logo-mob"
              src="assets/images/m-logo.png"
              style="width: 80%"
            />
          </a>
        </div>
        <!------------------------------logged in menu---------------------->
        <ion-list
          lines="none"
          id="inbox-list"
          class="center"
          style="margin-left: 2rem"
          *ngIf="sce?._loggedInUser?.id"
        >
         

          <ion-menu-toggle
            auto-hide="false"
            *ngIf="sce?._loggedInUser?.onboardingCompleted"
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'Dashboard' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['dashboard']"
              lines="none"
              detail="false"
              (click)="selectedMenu = 'Dashboard'"
            >
              <ion-label class="side-menu">Dashboard</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!----my-menu-->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="sce?._loggedInUser?.onboardingCompleted"
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'My Menu' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['/my-menu']"
              lines="none"
              detail="false"
              (click)="selectedMenu = 'My Menu'"
            >
              <ion-label class="side-menu">My Menu</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!----Sync Square-->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="
              sce?._loggedInUser?.squareCompleted
            "
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'Sync Square' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['/onboarding/square']"
              lines="none"
              detail="false"
              (click)="
                userProvider._showSquareDetails = 'sqDash';
                selectedMenu = 'Sync Square'
              "
            >
              <ion-label class="side-menu">Square Data</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!----menu-builder-->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="sce?._loggedInUser?.onboardingCompleted"
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'Menu Builder' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['/menu-builder']"
              lines="none"
              detail="false"
              (click)="selectedMenu = 'Menu Builder'"
            >
              <ion-label class="side-menu">Menu Builder</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!----My Orders-->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="sce?._loggedInUser?.onboardingCompleted"
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'My Orders' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['/my-orders']"
              lines="none"
              detail="false"
              (click)="selectedMenu = 'My Orders'"
            >
              <ion-label class="side-menu">My Orders</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!----My Profile-->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="
              sce?._loggedInUser?.onboardingCompleted
              
            "
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'My Profile' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['/profile']"
              lines="none"
              detail="false"
              (click)="selectedMenu = 'My Profile'"
            >
              <ion-label class="side-menu">My Profile</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!----Settings-->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="sce?._loggedInUser?.onboardingCompleted"
          >
            <ion-item
              class="ion-item"
              [ngClass]="selectedMenu == 'Settings' ? 'sel' : ''"
              routerDirection="root"
              [routerLink]="['/settings']"
              lines="none"
              detail="false"
              (click)="selectedMenu = 'Settings'"
            >
              <ion-label class="side-menu">Settings</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item
              class="ion-item cursor"
              lines="none"
              detail="false"
              (click)="logOut()"
            >
              <ion-label class="side-menu">Log out</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!------------------------------public menu---------------------->
        <ion-list
          lines="none"
          id="inbox-list"
          class="center"
          style="margin-left: 2rem"
          *ngIf="!sce?._loggedInUser?.id"
        >
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" class="os-bold f-17">
              <a href="https://tangychef.com" class="side-menu">Home</a>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item
              class="ion-item cursor"
              lines="none"
              detail="false"
              (click)="signin()"
            >
              <ion-label class="side-menu">Sign In</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
