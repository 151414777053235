import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { SceService } from '../sce.service';
import { ApiService } from '../api/api.service';
import * as _ from 'lodash';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class RideService {
  cartTotal;
  totalEarnings;
  rideDriverInfo;
  selectedRides = [];
  totalMealsCost;
  orderStatus = 'pending';
  _switch = 'today';
  calendarArr = [];
  totalMeals = 0;

  _filter = null;
  earn: any = {};
  calcEarnings = { cost: 0, meals: 0 };
  selectedCal = '';
  data: any = {
    pending: [],
    cancelled: [],
    completed: [],
    selectedMeals: {
      // assembled meals
      pending: {},
      cancelled: {},
      completed: {},
    },
    selectedMeals2: {
      // assembled meals
      pending: [],
      cancelled: [],
      completed: [],
    },
    mySortedMeals: [], // past - later meals by date
    myFinalMeals: [],
    myFinalMeals2: [],
    mealsName: [],

    myFinalMeals_Ern: [],
    mealsName_Ern: [],
  };
  loadOrdersListner;
  singleRide;

  single = [];
  l_single = [];
  multiChart = [];

  _chart = 'line';
  _chartName = 'Sold Meals';
  _chartColor = 'blue';
  dataArr: any[] = [];
  mergeChartOpt: any;
  mergePieOpt0: any;
  mergePieOpt1: any;
  chartData = [
    { name: 'Sold Meals', data: [], color: 'blue' },
    { name: 'Revenue', data: [], color: 'orange' },
    { name: 'Profit', data: [], color: 'green' },
  ];

  pickupToggleDisabled = false;
  choosenDate;
  choosenDay;
  todayDelivery;
  labelOption = {};
  cuisine_list: any[] = [];

  constructor(
    private api: ApiService,
    private sce: SceService,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private util: UtilService
  ) {}

  getACollectionData(collection: string) {
    return new Promise<any>((resolved, rejected) => {
      this.apiService.getOrderedList(collection).subscribe(
        (res: any) => {
          resolved(res);
        },
        (err) => {
          resolved([]);
          console.log('err get all meal data ->', err);
        }
      );
    });
  }

  getAllMealData() {
    return new Promise<any>((resolved, rejected) => {
      if (!this.sce._loggedInUser || !this.sce._loggedInUser.id) rejected;
      else {
        this.util.showLoading('loadOrders', 'Loading Orders...', 1300);
        this.api.loadOrders(this.sce._loggedInUser.id).subscribe(
          (res: any) => {
            let dishList = [];

            if (res && res.length > 0) {
              res.forEach((o: any) => {
                o.order.cart.forEach((dish: any) => {
                  const spl = Number(dish.spiceLevel);
                  const spiceLevel = typeof spl === 'number' ? spl : -1;
                  if (dish.vendorId === this.sce._loggedInUser.id)
                    dishList.push({
                      createdAt: o.createdAt,
                      customer: o.customer,
                      hub: o.hub,
                      payment: o.payment,
                      userId: o.userId,

                      order_id: o.id,
                      orderId: o.order.orderId,
                      resto_ids: o.order.resto_ids,
                      total: o.order.total,
                      instructions: o.instructions,

                      id: dish.id,
                      itemId: dish.itemId,
                      dishId: dish.dishId,
                      image: dish.image,
                      qty: 1,
                      scheduledDate: dish.scheduledDate,
                      cost: dish.cost,
                      name: dish.name,
                      request_accepted: dish.request_accepted,
                      request_completed: dish.request_completed,
                      request_timeout: dish.request_timeout,
                      spiceLevel,
                      tagsList: dish.tagsList ? dish.tagsList : [],
                      vendorId: dish.vendorId,
                      reHeatIndex: dish.reHeatIndex,
                      vendorName:
                        dish.vendor.firstName + ' ' + dish.vendor.lastName,
                      zone: {
                        zone: dish.vendor.zone,
                        hub: dish.vendor.hub,
                        hubAddress: dish.vendor.hubAddress,
                      },
                    });
                });
              });
            }

            this.data.selectedMeals.pending = dishList.filter(
              // pending ......   if not timed out & not completed
              (el) => !el.request_timeout && !el.request_completed
            );

            this.data.selectedMeals.cancelled = dishList.filter(
              // pending ......   if timed out  & not completed
              (el) => el.request_timeout && !el.request_completed
            );

            this.data.selectedMeals.completed = dishList.filter(
              // completed ......  if completed
              (el) => el.request_completed
            );

            this.todayDelivery = this.datePipe.transform(
              new Date(),
              'EEE, MMM d, y'
            );

            console.log(
              'dishList ..............',
              this.todayDelivery,
              dishList
            );
            console.log(
              'selected Meals ..............',
              this.data.selectedMeals
            );
            this.filterDishesData(this.choosenDate ? this.choosenDate : '');
            this.util.hideLoading();
            resolved(true);
          },
          (err) => {
            this.util.hideLoading();
            rejected(err);
            console.log('getAllMealData err -=>', err);
          }
        );
      }
    });
  }

  /**-----------------------------------------
   *
   * @param pending
   * @param cancelled
   * @param completed
   */
  groupByOrderId(list: any) {
    this.data.myFinalMeals2 =
      list && list.length > 0 ? this.filterByOrderId(list) : [];
  }

  /**-----------------------------------------
   *
   * @param list
   * @returns
   */
  filterByOrderId(list: any) {
    let dishList2 = [];
    let l = [];

    const filterIDs = _.groupBy(list, 'orderId');
    const arr = Array.from(Object.keys(filterIDs), (k) => [
      `${k}`,
      filterIDs[k],
    ]);

    console.log('lodash list ======================->', arr);

    if (arr && arr.length > 0) {
      arr.forEach((el: any) => {
        if (el && el.length > 0) {
          dishList2.push({
            orderId: el[0],
            items: el[1],
          });
        }
      });
    }
    return dishList2;
  }

  /**-----------------------------------------
   *
   * @param dt
   */
  filterDishesData(dt: string) {
    // calc total cost for default orderStatus
    this.mealsCost(this.data.selectedMeals[this.orderStatus], dt);
    this.loadChartData(this.data.selectedMeals['completed']);
    this.mealsCost_Ern(this.data.selectedMeals['completed']);
  }

  /**
   * this.data.selectedMeal = myList
   * @param myList
   */
  mealsCost(myList, scheduledDate?: any) {
    this.data.mySortedMeals = [];
    this.data.myFinalMeals = [];
    this.totalMealsCost = 0;
    let arr = [];

    // group by date
    this.data.mySortedMeals = this.sortTotalsByDay(myList);
    this.data.revenu = this.data.mySortedMeals;

    let f: any;
    if (this.data.mySortedMeals && this.data.mySortedMeals.length > 0) {
      // filter by date
      if (scheduledDate) {
        this.choosenDate = scheduledDate;
        const _list = this.filterData(
          this.data.mySortedMeals,
          this.choosenDate
        );
        f = _list.length > 0 ? _list[0] : {};
      } else {
        this.choosenDate = this.data.mySortedMeals[0].date;
        f = this.data.mySortedMeals[0];
      }

      arr = f && f.filterMeals && f.filterMeals.length > 0 ? f.filterMeals : [];

      // 2nd Tab, find meals by order #
      this.groupByOrderId(arr);

      this.data.myFinalMeals = arr.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      // find meals # & cost
      if (this.data.myFinalMeals && this.data.myFinalMeals.length > 0)
        this.data.myFinalMeals.forEach(
          (meal: any) => (this.totalMealsCost += Number(meal.cost))
        );

      // uniq Dish dates - calendar
      this.calendarArr = [];
      this.data.mySortedMeals.forEach((el) => {
        const dt = el.date;
        const dName = this.datePipe.transform(dt, 'EEE');
        const dNum = this.datePipe.transform(dt, 'MMM d');
        this.calendarArr.push({ dName, dNum, dt });
      });

      this.data.mealsName = this.groupingmyFinalMeals(this.data.myFinalMeals);
      this.data.orderIds = this.groupingmyFinalMeals2(this.data.myFinalMeals);
      if (this.orderStatus === 'pending') this.getOrderPickup(this.calendarArr);
    }

    console.log('Data ->', this.orderStatus, this.data);
  }

  getOrderPickup(list: any) {
    const orderPickup = this.updOrderPickup();
    const vendorCalendar = this.sce._loggedInUser?.vendorCalendar
      ? this.sce._loggedInUser?.vendorCalendar
      : [];

    console.log('old cal ->', vendorCalendar);
    console.log('new cal ->', list);

    //compare current cal with vendor's cal
    if (vendorCalendar.length === 0) {
      list.forEach((el: any) => {
        vendorCalendar.push({
          dt: el.dt,
          dName: el.dName,
          dNum: el.dNum,
          selected: orderPickup,
        });
      });
    } else {
      // create intersection btw calendarArr & vendorCalendar
    }
  }

  updOrderPickup(): any {
    let orderPickup = false;
    let idx = -1;
    if (
      this.sce._loggedInUser.questions &&
      this.sce._loggedInUser.questions.length >= 4
    ) {
      idx = this.sce._loggedInUser.questions[4].answer.findIndex(
        (el) => el.selected
      );
    }
    if (idx > -1) {
      orderPickup =
        this.sce._loggedInUser.questions[4].answer[idx].name === 'Everytime';
    }
    console.log('pickup ->', orderPickup, this.sce._loggedInUser.questions[4]);
    return orderPickup;
  }

  checkPickupTime(dt: any, orderPickup: boolean) {
    // sync order pickup with current time
    const time = this.datePipe.transform(new Date(), 'HH:mm');
    const hrMn: any = time.split(':');
    const minutes = hrMn[0] * 60 + hrMn[1];

    if (minutes > 11 * 60) {
      this.pickupToggleDisabled = true;
      orderPickup = false;
    }
    return orderPickup;
  }

  filterData(l: any, dt: any) {
    return l.filter((res: any) => res.date === dt);
  }

  /**
   * grouping by date, spice leve; & dishes name
   * @param list
   * @returns
   */
  groupingmyFinalMeals(list) {
    let temp = '';
    let names = [];
    list.forEach((el, index) => {
      if (temp === '') names.push(el.name);
      if (temp && temp !== el.name) {
        names.push(el.name);
      }
      temp = el.name;
    });

    let chili;
    let total;
    let totalCost;
    let grouppedMealsbyName = [];
    let _instr;
    let found;
    names.forEach((name) => {
      total = 0;
      totalCost = 0;
      chili = [];
      _instr = false;
      list.forEach((meal, index) => {
        found = -1;
        if (name === meal.name) {
          if (meal.spiceLevel > -1) {
            found = chili.findIndex((f) => meal.spiceLevel === f.chili);
            if (found >= 0) chili[found].number += 1;
            else chili.push({ number: 1, chili: meal.spiceLevel });
          }
          totalCost += Number(meal.cost);
          total += 1;
          if (meal.instructions) _instr = true;
        }
      });
      console.log(chili);
      grouppedMealsbyName.push({ name, chili, total, totalCost, _instr });
    });

    return grouppedMealsbyName;
  }

  /**
   * grouping by order Id, names & spice livel
   * @param list
   * @returns
   */
  groupingmyFinalMeals2(list: any) {
    let orderIds = [];
    let names = [];

    // find order IDs

    list.forEach((el) => {
      const idx = orderIds.findIndex((id) => el.orderId === id);
      if (idx < 0) orderIds.push(el.orderId);
    });

    // find names
    list.forEach((el) => {
      const idx = names.findIndex((name) => el.name === name);
      if (idx === -1) names.push(el.name);
    });

    let chili;
    let total;
    let totalCost;
    let grouppedMealsbyIds = [];
    let _instr;
    let found;
    let dishesList;
    orderIds.forEach((_orderId) => {
      dishesList = [];
      names.forEach((name) => {
        total = 0;
        totalCost = 0;
        chili = [];
        _instr = false;
        list.forEach((meal, index) => {
          found = -1;
          if (_orderId === meal.orderId && name === meal.name) {
            if (meal.spiceLevel > -1) {
              found = chili.findIndex((f) => meal.spiceLevel === f.chili);
              if (found >= 0) chili[found].number += 1;
              else chili.push({ number: 1, chili: meal.spiceLevel });
            }
            totalCost += Number(meal.cost);
            total += 1;
          }
        });
        if (total) dishesList.push({ name, chili, total, totalCost });
      });
      grouppedMealsbyIds.push({ orderId: _orderId, dishesList });
    });
    console.log('orders by ids ->', grouppedMealsbyIds);
    return grouppedMealsbyIds;
  }

  clearOrdersListener() {
    if (this.loadOrdersListner) {
      clearInterval(this.loadOrdersListner);
      this.loadOrdersListner = undefined;
    }
  }

  // grouping meals by date
  sortTotalsByDay(list) {
    let total = 0;
    let temp = null;
    let filterMeals = [];
    let mySortedMeals = [];
    const sortedList = list.sort(this.sortArray);
    sortedList.forEach((el, index) => {
      if (temp && temp === el.scheduledDate) {
        total += 1;
        filterMeals = filterMeals.concat([el]);
        if (list.length === index + 1)
          mySortedMeals.push({ date: temp, total, filterMeals });
      } else {
        if (temp && temp !== el.scheduledDate) {
          mySortedMeals.push({ date: temp, total, filterMeals });
        }
        total = 1;
        temp = el.scheduledDate;
        filterMeals = [el];
        if (list.length === index + 1)
          mySortedMeals.push({ date: temp, total, filterMeals });
      }
    });
    return mySortedMeals;
  }

  //----------------------------------------------
  //----------------------------------------------
  //----------------------------------------------

  // sort by date
  sortArray(a, b) {
    return (
      new Date(a.scheduledDate).getTime() - new Date(b.scheduledDate).getTime()
    );
  }

  loadChartData(list) {
    // revenue
    const arr = list.map((el: any) => {
      return {
        date: this.datePipe.transform(el.scheduledDate, 'yyyy/MM/dd'),
        cost: parseFloat(el.cost),
      };
    });

    let chartData = {
      cost: [],
      total: [],
      profit: [],
    };

    // by day
    const uniqDate = _.uniqBy(arr, 'date');
    const groupedByDay = _.groupBy(arr, 'date');

    uniqDate.forEach((dt) => {
      // console.log(dt.date, groupedByDay[dt.date]);
      const totalCost = _.sumBy(groupedByDay[dt.date], 'cost');
      chartData.cost.push({ date: dt.date, value: totalCost.toFixed(2) });
      chartData.total.push({
        date: dt.date,
        value: groupedByDay[dt.date].length,
      });
    });

    this.chartData[0].data = chartData.total;
    this.chartData[1].data = chartData.cost;

    this.changeChart(1, 'line');
  }

  changeChart(index: number, chart: string) {
    if (index >= 0) {
      this.labelOption = {
        show: true,
        fontSize: 20,
        formatter: function (params) {
          return `${index === 0 ? '' : '$'}${params.data.value}`;
        },
      };
      this._chartName = this.chartData[index].name;
      this.dataArr = this.chartData[index].data.sort(this.sortArraybyDate);
      this._chartColor = this.chartData[index].color;
    }
    if (chart) this._chart = chart;
    this.mergeChartOpt = {
      dataset: {
        source: this.dataArr,
      },
      title: {
        text: this._chartName,
      },
      series: {
        type: this._chart,
        name: this._chartName,
        itemStyle: {
          color: this._chartColor,
        },
        label: this.labelOption,
      },
    };

    console.log('>>--> mergeChartOpt ->', this.mergeChartOpt);
  }

  // sort by date
  sortArraybyDate(a, b) {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  }

  changePie0() {
    this.mergePieOpt0 = {
      series: [
        {
          data: this.single.sort((a, b) => {
            return b.value - a.value;
          }),

          label: {
            show: true,
            fontSize: 14,
            formatter: function (params: any) {
              return `${params.data.name}: ${params.data.value}`;
            },
          },
        },
      ],
    };
  }

  changePie1() {
    this.mergePieOpt1 = {
      series: [
        {
          data: this.l_single.sort((a, b) => {
            return b.value - a.value;
          }),

          label: {
            show: true,
            fontSize: 14,
            formatter: function (params: any) {
              return `${params.data.name}: $${params.data.value}`;
            },
          },
        },
      ],
    };
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - -

  mealsCost_Ern(myList) {
    const temNames = myList.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    this.data.mealsName_Ern = this.groupingmyFinalMeals(temNames);
    this.single = this.chartArr(this.data.mealsName_Ern);
    this.l_single = this.chartLineArr(this.data.mealsName_Ern);

    this.multiChart = [
      {
        name: 'Meals',
        series: this.single,
      },
    ];

    console.log('mealsName_Ern -->', this.data.mealsName_Ern);
    console.log('single -->', this.single);
    console.log('l_single -->', this.l_single);

    this.changePie0();
    this.changePie1();
  }

  chartArr(list): any {
    let l = [];
    list.forEach((el) => {
      l.push({ name: el.name, value: el.total });
    });
    return l;
  }

  chartLineArr(list): any {
    let l = [];
    list.forEach((el) => {
      l.push({ name: el.name, value: el.totalCost });
    });
    return l;
  }

  /**
   *
   * @param list
   * @returns
   */
  calcMe(list) {
    let totalCost = 0;
    let totalMeals = 0;
    list.forEach((el) => {
      el.order.cart.forEach((meal) => {
        if (meal.vendorId === this.sce._loggedInUser.id) {
          totalCost += Number(meal.cost);
          totalMeals += 1;
        }
      });
    });
    return { cost: totalCost, meals: totalMeals };
  }

  /**
   *
   * @param index
   */
  setVendorStatus(index: number) {
    if (this.sce._loggedInUser.id) {
      this.apiService
        .updateDataApi('vendors', this.sce._loggedInUser.id, {
          vendorCalendar: this.sce._loggedInUser.vendorCalendar,
        })
        .subscribe(
          (res) => {
            console.log('cal saved');
          },

          (err) => {
            console.log('cal not saved');
          }
        );
    }
    console.log('vend cal ->', this.sce._loggedInUser.vendorCalendar);
  }

  /**
   *
   * @param order
   */
  async orderCompleted(order: any) {
    console.log('oooooooooooooorder ->', order);
    const alert = await this.util.createAlert(
      'Confirm',
      false,
      'Do you want to mark this order as completed? ' + name,
      {
        text: 'No',
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: 'Yes',
        handler: async () => {
          // updated order items status
          try {
            const currentOrderId = order.items[0].order_id;
            let oo = await this.api.getPromiseCollection(
              'orders',
              currentOrderId
            );
            order.items.forEach(async (el: any) => {
              oo.order.cart.forEach((item) => {
                if (item.dishId === el.dishId) {
                  item.request_accepted = true;
                  item.request_completed = true;
                }
              });
            });

            console.log(
              'ooooooooorder-> order items, original order: ',
              currentOrderId,
              oo
            );
            this.api
              .updateDataApi('orders', currentOrderId, { order: oo.order })
              .subscribe(() => {
                // this.sendNotification(order);
                this.getAllMealData();
              });
          } catch (e) {
            console.log('order not found');
          }
        },
      }
    );
    await alert.present();
  }

  sendNotification(order: any) {
    let name = order.items ? order.items[0].customer.firstName : '';
    let number = order.items[0].customer.phoneNumber;
    let email = order.items[0].customer.email.toLowerCase();

    console.log('sending sms, email to >>>>>-->', name, number, email);

    // send SMS-------------------------
    const sms = `Hey ${name}! Your order is ready for pickup. Tangy Chef`;
    try {
      this.api.sendTangySMS(number, sms).then(
        (el) => {
          console.log('SMS sent to -->', number);
        },
        (e) => {
          console.log('SMS failed to send-->', e);
        }
      );
    } catch (e) {
      console.log('SMS failed to send-->', e);
    }

    // send email------------------------
    const message = `
              Hey ${name}!

                    Your order is ready for pickup.
              
              Thanks,
              Tangy Chef
        `;
    const data = {
      name: name, // To
      email: email, // To
      senderName: 'Tangy Chef', // from
      subject: 'Order Completed',
      message: message,
    };

    try {
      this.api.sendTangyEmail(data).then(
        (el) => {
          console.log('email sent!');
        },
        (e) => {
          console.log('email not sent', e);
        }
      );
    } catch (e) {
      console.log('email not sent', e);
    }
  }
}
