import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UserCredential } from 'src/app/models/user';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthFormComponent } from 'src/app/component/auth-form/auth-form.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UtilService } from 'src/app/services/util/util.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InitUserProvider } from 'src/app/services/inituser/inituser.service';
import { SceService } from 'src/app/services/sce.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {
  @ViewChild(AuthFormComponent)
  @Output()
  closeModal = new EventEmitter<any>();

  resetPasswordForm: AuthFormComponent;
  constructor(
    private authService: AuthService,
    private alertCtrl: AlertController,
    private router: Router,
    private util: UtilService,
    private modalCtr: ModalController,
    private apiService: ApiService,
    private userProvider: InitUserProvider,
    private sce: SceService
  ) {}

  ngOnInit() {}

  /**
   *
   * @param credentials
   * @returns
   */
  async resetPassword(credentials: UserCredential): Promise<void> {
    if (
      !credentials.email ||
      credentials.email.indexOf('.') < 0 ||
      credentials.email.indexOf('@') < 0
    ) {
      this.util.showMsg('Wrong email, Please correct & try again');
      return;
    }
    await this.util.showLoading2('');
    this.apiService
      .postHttpData('resetvendorpwd', {
        email: credentials.email.toLocaleLowerCase(),
      })
      .subscribe({
        next: async (res: any) => {
          await this.util.hideLoading2();
          console.log('res->', res);
          const alert = await this.alertCtrl.create({
            message:
              'Please check your email inbox and click on the reset link',
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  this.dismiss();
                },
              },
            ],
          });
          await alert.present();
        },
        error: async (err: any) => {
          await this.util.hideLoading2();
          let errMsg = 'Reset Failed, Please try again';
          if (err?.error === "Email doesn't exist")
            errMsg = "Email doesn't exist";
          this.util.showMsg(errMsg);
          console.log(err);
        },
      });
  }

  /**
   *
   */
  async logOut(): Promise<void> {
    await this.authService.logout();
    this.userProvider.logout().then((res) => {
      this.sce._loggedInUser = {};
      this.sce.initOnboarding();
      this.sce._isLogin = true;
    });
    this.router.navigateByUrl('');
  }

  async dismiss() {
    await this.modalCtr.dismiss();
  }
}
