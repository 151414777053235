import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map, take } from 'rxjs/operators';
import { BaseDatabaseModel } from '../ride/base-dto.model';

@Injectable()
export class FirestoreService {
  userid: any;

  constructor(public store: AngularFirestore) {
    // this.util.userid.subscribe(res => {
    this.userid = 1;
    // });
  }

  public createWithId<T extends BaseDatabaseModel>(
    collection: string,
    data: T
  ): Promise<void> {
    console.log('DATA -> ', data);
    return this.store
      .doc<T>(`${collection}/${data.id}`)
      .set(this.addCreatedAt(data));
  }
  public async create<T extends BaseDatabaseModel>(
    collection: string,
    data: T
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    const doc = await this.store
      .collection<T>(collection)
      .add(this.addCreatedAt(data));
    return doc.get();
  }

  public get<T extends BaseDatabaseModel>(collection: string): Observable<T[]> {
    return this.store
      .collection<T>(collection, (ref) =>
        ref.where('uid', '==', `${this.userid}`)
      )
      .valueChanges({ idField: 'id' })
      .pipe(take(1));
  }

  public getOne<T extends BaseDatabaseModel>(
    collection: string,
    id: string
  ): Observable<T> {
    return this.store
      .doc<T>(`${collection}/${id}`)
      .valueChanges()
      .pipe(take(1));
  }

  public update<T extends BaseDatabaseModel>(
    collection: string,
    id: string,
    document: Partial<T>
  ): Promise<void> {
    return this.store
      .doc<T>(`${collection}/${id}`)
      .update(this.addUpdatedAt(document));
  }

  public find<T extends BaseDatabaseModel>(
    collection: string,
    queryFn: QueryFn
  ): Observable<T[]> {
    return new Observable((observer) => {
      this.store
        .collection<T>(collection, queryFn)
        .valueChanges({ idField: 'id' })
        .pipe(take(1))
        .subscribe(
          (docs) => {
            observer.next(docs);
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  public findOne<T extends BaseDatabaseModel>(
    collection: string,
    queryFn: QueryFn
  ): Observable<T> {
    return new Observable((observer) => {
      this.store
        .collection<T>(collection, queryFn)
        .valueChanges({ idField: 'id' })
        .pipe(take(1))
        .subscribe(
          (docs) => {
            observer.next(docs[0]);
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  public delete<T extends BaseDatabaseModel>(
    collection: string,
    id: string
  ): Promise<any> {
    return this.store.doc<T>(`${collection}/${id}`).delete();
  }

  public uploadFile(
    folderName: string,
    downloadUrl: string,
    fileName: string
  ): Promise<any> {
    return this.store
      .collection<{ downloadUrl: string; fileName: string; uid: string }>(
        `fileReferences`
      )
      .add({ downloadUrl, fileName, uid: this.userid });
  }

  addCreatedAt(data) {
    return {
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
  addUpdatedAt(data) {
    return {
      ...data,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
}

export interface FirestoreQuery {
  field: string;
  operation: firebase.firestore.WhereFilterOp;
  searchKey: string;
}
