import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { SceService } from 'src/app/services/sce.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-profile-prev',
  templateUrl: './prev-modal.component.html',
  styleUrls: ['./prev-modal.component.scss'],
})
export class profilePreviewComponent {
  index: number;
  asUser: boolean;

  constructor(
    private navParams: NavParams,
    private modalCtr: ModalController,
    public sce: SceService,
    private apiService: ApiService,
    private util: UtilService
  ) {
    this.index = this.navParams.get('index');
    this.asUser = this.navParams.get('asUser');
  }

  async ngOnInit() {
    if (!this.sce.data.meals || this.sce.data.meals.length === 0) {
      console.log('1-prev modal ---')
      await this.sce.getAllMealData();
      console.log('2-prev modal theme ---')
      console.log('meals in profile ->', this.sce.data);
    }
  }

  async close(index: number) {
    await this.modalCtr.dismiss(index);
  }

  save() {
    const menuTheme = {
      activeMenu: this.sce.activeMenu,
      _theme: this.sce._theme,
      _style: this.sce._style,
    };
    this.sce._loggedInUser.menuTheme = menuTheme;
    this.apiService
      .updateDataApi('vendors', this.sce._loggedInUser.id, {
        menuTheme: menuTheme,
      })
      .subscribe(
        (res) => {
          this.util.showMsg('menu template saved successfully');
          console.log('saved ->', this.sce._loggedInUser?.menuTheme);
        },
        (err) => {
          this.util.showMsg('Menu template not saved');
          console.log('not saved');
        }
      );

    console.log('menu theme ->', menuTheme);
  }
}
