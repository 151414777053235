import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-msg-modal',
  templateUrl: './msg-modal.component.html',
  styleUrls: ['./msg-modal.component.scss'],
})
export class MsgModalComponent implements OnInit {
  digits = '';
  phone: '';
  email: '';
  name: '';
  code: any;

  authVerif: any = {};
  next = false;

  constructor(
    private navParams: NavParams,
    private modalCtr: ModalController,
    private apiService: ApiService,
    private util: UtilService
  ) {
    this.phone = this.navParams.get('phone');
    this.email = this.navParams.get('email');
    this.name = this.navParams.get('name');

    this.authVerif = {
      phone: this.phone,
      phoneVerified: false,
      phoneCode: '',
      email: this.email.toLowerCase(),
      emailVerified: false,
      emailCode: '',
    };
    this.reset();
  }

  ngOnInit() {
    this.nextVerif();
  }

  async close() {
    await this.modalCtr.dismiss(this.authVerif);
  }

  async sendCode(str: string) {
    try {
      this.util.showLoading('', '');
      if (str === 'phone') {
        this.apiService.sendOtp(this.phone).then(
          (el) => {
            this.code = el;
            // console.log('phone code ->', this.code);
            this.util.hideLoading();
          },
          (e) => {
            this.util.hideLoading();
            // console.log(e);
          }
        );
      } else {
        const emailVerifCode = Math.floor(Math.random() * 900000) + 100000;

        // // send code by SMS-------------------------

        // const sms = 'Your verification code is: ' + emailVerifCode

        // try {
        //   this.apiService.sendTangySMS(this.phone, sms).then(
        //     (el) => {
        //       console.log('SMS sent to -->', this.phone);
        //       this.code = emailVerifCode;
        //     },
        //     (e) => {
        //       console.log('SMS failed to send-->', e);
        //     })
        // } catch (e) {
        //   console.log('SMS failed to send-->', e);
        // }

        // send code by email-------------------------

        const message = `
              Hey ${this.name}!

              A sign up attempt requires further verification. To complete your sign up, please enter the verification code to verify your email.

              Verification Code: <strong>${emailVerifCode}</strong>

              Please ignore this email, if you did not attempt to sign up to our Tangy Chef services.
              
              Thanks,
              Tangy Chef
        `;

        const data = {
          name: this.name, // To
          email: this.email.toLowerCase(), // To
          senderName: 'Tangy Chef', // from
          subject: 'Please verify your email',
          message: message,
        };

        //  console.log('verify email ->', emailVerifCode, data);

        this.apiService.sendTangyEmail(data).then(
          (el) => {
            this.code = emailVerifCode;
            this.util.hideLoading();
          },
          (e) => {
            this.util.hideLoading();
            console.log(e);
          }
        );
      }
    } catch (e) {
      this.util.hideLoading();
      alert(e);
    }
  }

  async verify(str) {
    if (this.code == this.digits) {
      if (str === 'phone') {
        this.authVerif.phoneVerified = true;
        this.authVerif.phoneCode = this.code;
      } else {
        this.authVerif.emailVerified = true;
        this.authVerif.emailCode = this.code;
      }
      console.log(this.authVerif);
    } else this.util.showAlert(null, `Invalid code, please try again.`);
  }

  reset() {
    this.digits = '';
  }

  nextVerif() {
    this.next = true;
    this.reset();
    this.sendCode('email');
  }
}
