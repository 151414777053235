import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { InitUserProvider } from './services/inituser/inituser.service';
import { SceService } from './services/sce.service';
import { UtilService } from './services/util/util.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'House.png',
    },
    {
      title: 'My Menu',
      url: '/my-menu',
      icon: 'Spoon.png',
    },
    {
      title: 'Sync Square',
      url: '/onboarding/square',
      icon: 'Spoon.png',
    },
    {
      title: 'Menu Builder',
      url: '/menu-builder',
      icon: 'Spoon.png',
    },
    {
      title: 'My Orders',
      url: '/my-orders',
      icon: 'clipboard.png',
    },
    {
      title: 'My Profile',
      url: '/profile',
      icon: 'user.png',
    },

    // {
    //   title: 'Messages',
    //   url: '/messages',
    //   icon: 'approve chat.png',
    // },

    {
      title: 'Settings',
      url: '/settings',
      icon: 'settings.png',
    },
  ];

  selectedMenu = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    public sce: SceService,
    public readonly userProvider: InitUserProvider,
    private util: UtilService
  ) {
    this.sce.chefLanding = false;
  }

  async logOut(): Promise<void> {
    await this.authService.logout();
    this.userProvider.logout().then((res) => {
      this.sce._loggedInUser = {};
      this.sce.initOnboarding();
      this.sce._isLogin = true;
    });

    this.router.navigateByUrl('');
  }

  signin() {
    this.sce._isLogin = true;
    this.router.navigateByUrl('');
  }

  gotoPage(page: string) {}

  /**---------------------------------------------
   *
   * @param url
   * @param index
   */
  gotoComponenent(url: string, index: number) {
    this.userProvider._showSquareDetails = 'business';
    this.util.navigateForward(url, {
      queryParams: { index },
    });
  }
}
