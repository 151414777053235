<!-------------------------sign in----------------------->

<form *ngIf="sce._isLogin && !isPasswordResetPage" [formGroup]="authSigninForm">
  <section class="width-100">
    <div class="margin-top-2 poppins-bold f1-4">
      Please sign-in your Account
    </div>

    <!---------------email-------------->

    <div class="my-input margin-top-2">
      <label>Email Address<span class="required">*</span></label>
      <input
        formControlName="email"
        type="email"
        placeholder=""
        fill="outline"
        pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$"
        [class.invalid]="
          !authSigninForm.controls['email'].valid &&
          authSigninForm.controls['email'].touched
        "
      />
    </div>
    <div
      class="err"
      *ngIf="!authSigninForm.controls['email'].valid && _isFormClicked"
    >
      <ion-icon name="alert-circle" color="danger"></ion-icon>Invalid e-mail
    </div>

    <!---------------pwd-------------->

    <div class="my-input margin-top-1 relative">
      <label>Enter Password <span class="required">*</span></label>
      <input
        formControlName="password"
        [type]="_showPassword ? 'text' : 'password'"
        placeholder=""
        fill="outline"
        [class.invalid]="
          !authSigninForm.controls['password'].valid &&
          authSigninForm.controls['password'].touched
        "
      />

      <ion-icon
        slot="end"
        (click)="_showPassword = !_showPassword"
        [name]="_showPassword ? 'eye-off' : 'eye'"
        class="icon eye"
      ></ion-icon>
    </div>

    <div
      class="err"
      *ngIf="!authSigninForm.controls['password'].valid && _isFormClicked"
    >
      <ion-icon name="alert-circle" color="danger"></ion-icon>Invalid password
    </div>

    <div class="flex f-align-center f-just-btw f-wrap margin-top-2">
      <div class="my-input-checkbox flex f-align-center">
        <input
          id="acceptTerms"
          type="checkbox"
          formControlName="acceptTerms"
          [class.invalid]="
            !authSigninForm.controls['acceptTerms'].valid &&
            authSigninForm.controls['acceptTerms'].touched
          "
        />
        <label for="acceptTerms" class="cursor terms"
          >I agree to all the
          <a
            class="orange-dark poppins-med"
            href="https://tangychef.com/terms-and-conditions/"
            target="_blank"
            >Terms
          </a>
          and
          <a
            class="orange-dark poppins-med"
            href="https://tangychef.com/privacy-policy/"
            target="_blank"
            >Privacy Policy</a
          >
          <span class="required">*</span></label
        >
      </div>

      <div
        class="orange-dark poppins-med cursor right f-pwd"
        (click)="forgotPass()"
      >
        Forgot password?
      </div>
    </div>

    <div
      class="err margin-left-1"
      *ngIf="!authSigninForm.controls['acceptTerms'].valid && _isFormClicked"
    >
      <ion-icon name="alert-circle" color="danger"></ion-icon>
      Required
    </div>

    <div class="center margin-top-4">
      <ion-button
        (click)="submitCredentials(authSigninForm)"
        color="warning"
        class="flex f-align-center f-just-center"
      >
        Sign-In
      </ion-button>

      <div class="grey-light-2 f1-1 margin-top-1">OR</div>

      <div class="social margin-top-1">
        <ion-button fill="clear" class="bg-white">
          <img src="assets/extend/svg/fb.svg" />
        </ion-button>

        <ion-button fill="clear" class="bg-white">
          <img src="assets/extend/svg/google.svg" />
        </ion-button>

        <ion-button fill="clear" class="bg-white">
          <img src="assets/extend/svg/insta.svg" />
        </ion-button>
      </div>
    </div>
  </section>
</form>

<!-------------------------Sign up----------------------->

<form [formGroup]="authForm" *ngIf="!sce._isLogin && !isPasswordResetPage">
  <div class="flex f-align-center">
    <h4>Welcome Chefs</h4>
    <img style="height: 1.5rem" src="assets/extend/svg/hand.svg" />
  </div>
  <h4 class="margin-top-1">Please Register your Account to get started!</h4>

  <ion-row class="margin-top-1">
    <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
      <div class="my-input">
        <label>First Name <span class="required">*</span></label>
        <input
          formControlName="firstName"
          type="text"
          placeholder=""
          [class.invalid]="
            !authForm.controls['firstName'].valid && _isFormClicked
          "
        />
      </div>

      <div
        class="err"
        *ngIf="!authForm.controls['firstName'].valid && _isFormClicked"
      >
        <ion-icon name="alert-circle" color="danger"></ion-icon>Invalid first
        name
      </div>
    </ion-col>

    <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
      <div class="my-input">
        <label>Last Name </label>
        <input
          formControlName="lastName"
          type="text"
          placeholder=""
          
        />
      </div>
      <div
        class="err"
        *ngIf="!authForm.controls['lastName'].valid && _isFormClicked"
      >
        <ion-icon name="alert-circle" color="danger"></ion-icon>Invalid last
        name
      </div>
    </ion-col>
  </ion-row>

  <!---------------email, phone-------------->

  <ion-row>
    <ion-col>
      <div class="my-input">
        <label>Email address<span class="required">*</span></label>
        <input
          formControlName="email"
          type="email"
          placeholder=""
          pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$"
          [class.invalid]="
            !authForm.controls['email'].valid &&
            authForm.controls['email'].touched
          "
        />
      </div>
      <div
        class="err"
        *ngIf="!authForm.controls['email'].valid && _isFormClicked"
      >
        <ion-icon name="alert-circle" color="danger"></ion-icon>Invalid e-mail
      </div>
    </ion-col>
  </ion-row>

  <!--------------password--------------->
  <ion-row>
    <ion-col size="12">
      <div class="relative my-input">
        <label>Create Password <span class="required">*</span></label>

        <input
          formControlName="password"
          [type]="_showPassword ? 'text' : 'password'"
          placeholder=""
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
          [class.invalid]="
            !authForm.controls['password'].valid &&
            authForm.controls['password'].touched
          "
        />

        <ion-icon
          slot="end"
          (click)="_showPassword = !_showPassword"
          [name]="_showPassword ? 'eye-off' : 'eye'"
          class="icon eye"
        ></ion-icon>
      </div>
      <div
        class="err"
        *ngIf="!authForm.controls['password'].valid && _isFormClicked"
      >
        <div><ion-icon name="alert-circle" color="danger"></ion-icon></div>
        <div>
          Password should be at least 6 characters with at least 1 uppercase, 1
          lowercase and 1 number
        </div>
      </div>
    </ion-col>

    <ion-col size="12">
      <div class="relative my-input">
        <label>Re-enter Password <span class="required">*</span></label>
        <input
          formControlName="confirmPassword"
          [type]="_showPassword ? 'text' : 'password'"
          placeholder=""
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
          [class.invalid]="
            !authForm.controls['confirmPassword'].valid &&
            authForm.controls['confirmPassword'].touched
          "
        />

        <ion-icon
          slot="end"
          (click)="_showPassword = !_showPassword"
          [name]="_showPassword ? 'eye-off' : 'eye'"
          class="icon eye"
        ></ion-icon>
      </div>
      <div
        class="err"
        *ngIf="!authForm.controls['confirmPassword'].valid && _isFormClicked"
      >
        <ion-icon name="alert-circle" color="danger"></ion-icon>
        <span *ngIf="f.confirmPassword.errors.required">
          Confirm Password is required
        </span>
        <span *ngIf="f.confirmPassword.errors.mustMatch">
          Passwords does not match
        </span>
      </div>
    </ion-col>
  </ion-row>

  <!---------------------------->

  <div class="my-input flex- f-align-center margin-top-2">
    <input
      id="terms"
      type="checkbox"
      slot="start"
      formControlName="acceptTerms"
      [class.invalid]="
        !authForm.controls['acceptTerms'].valid &&
        authForm.controls['acceptTerms'].touched
      "
    />
    <label for="terms" class="cursor terms"
      >I agree to all the
      <a
        class="orange-dark poppins-med"
        href="https://tangychef.com/terms-and-conditions/"
        target="_blank"
        >Terms</a
      >
      and
      <a
        class="orange-dark poppins-med"
        href="https://tangychef.com/privacy-policy/"
        target="_blank"
        >Privacy Policy</a
      >
      &nbsp; <span class="required">*</span></label
    >
    <div
      class="err"
      *ngIf="!authForm.controls['acceptTerms'].valid && _isFormClicked"
    >
      <ion-icon name="alert-circle" color="danger"></ion-icon>
      Required
    </div>
  </div>

  <div class="center margin-top-2">
    <ion-button
      (click)="submitCredentials(authForm)"
      color="warning"
      class="flex f-align-center f-just-center"
    >
      Register Account
    </ion-button>

    <div class="grey-light-2 margin-top-1">OR</div>

    <div class="social margin-top-1">
      <ion-button fill="clear" class="bg-white">
        <img src="assets/extend/svg/fb.svg" />
      </ion-button>

      <ion-button fill="clear" class="bg-white">
        <img src="assets/extend/svg/google.svg" />
      </ion-button>

      <ion-button fill="clear" class="bg-white">
        <img src="assets/extend/svg/insta.svg" />
      </ion-button>
    </div>
  </div>
</form>

<!-----------------------reset password------------------>

<form
  *ngIf="isPasswordResetPage"
  [formGroup]="authForm"
  style="padding: 2rem 1rem"
>
  <div class="center margin-bot-2">
    <h4>Enter your email to reset your password.</h4>
  </div>

  <div class="my-input">
    <input
      formControlName="email"
      type="email"
      placeholder=""
      pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$"
      [class.invalid]="
        !authForm.controls['email'].valid && authForm.controls['email'].touched
      "
    />
  </div>
  <div class="err" *ngIf="!authForm.controls['email'].valid && _isFormClicked">
    <ion-icon name="alert-circle" color="danger"></ion-icon>Please enter a valid
    email address
  </div>

  <ion-button
    [disabled]="!authForm.controls['email'].valid"
    (click)="resetPass(authForm)"
    color="warning"
    class="margin-top-2"
  >
    Reset Password
  </ion-button>
</form>
