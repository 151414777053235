<ion-content>
  <div class="right padding-1">
    <ion-icon name="close" (click)="dismiss()"></ion-icon>
  </div>

  <app-auth-form
    #resetPasswordForm
    (resetFormSubmitted)="resetPassword($event)"
    [actionButtonText]="'Reset Password'"
    [isPasswordResetPage]="true"
  ></app-auth-form>
</ion-content>
