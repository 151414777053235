<div class="flex border-bottom padding-1 flex-space-btw" *ngIf="!asUser">
  <div class="f-smaller flex-top flex-dir-col bold-600">
    Template Preview
    <div><hr class="hr-medium" /></div>
  </div>

  <ion-button size="small" color="warning" (click)="close(index)">
    Use this template
  </ion-button>
</div>

<div class="scroll" *ngIf="!asUser">
  <img src="assets/vendor-profile/Template {{ index }}.png" class="preview" />
</div>

<div class="scroll" *ngIf="asUser">
  <div class="flex flex-space-btw padding-1" *ngIf="!sce._showMenuTheme">
    <div>
      <img
        routerLink="/"
        slot="end"
        src="assets/images/logo.png"
        class="logo cursor"
      />
    </div>
    <div class="flex-row">
      <span class="f-regular bold-500">
        {{
          sce?.selectedVendor?.firstName + " " + sce?.selectedVendor?.lastName
        }}
      </span>
      <img
        [src]="
          sce?.selectedVendor?.profileImage
            ? sce?.selectedVendor?.profileImage + ' | image | async '
            : 'assets/images/blank-profile.png'
        "
        class="top-profile-img"
        alt=""
      />
    </div>
  </div>

  <app-temp0
    *ngIf="index == 0"
    (evt)="uploadPhoto($event)"
    [asUser]="true"
  ></app-temp0>

  <app-temp1
    *ngIf="index == 1"
    (evt)="uploadPhoto($event)"
    [asUser]="true"
  ></app-temp1>
</div>

<div class="margin-top-1 margin-bot-1 center">
  <ion-button class="bold-500" color="warning" (click)="close(-1)"
    >close</ion-button
  >
</div>
