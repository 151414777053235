<div id="msg-modal">
  <!--------------verify phone--------------->

  <!-- <div class="f-big center message" *ngIf="!authVerif?.phoneVerified">
    <span
      >Please enter the 6-digits verification code sent to number ending in
      ...{{ phone.substr(-4, 4) }}</span
    >

    <div class="flex-center-2 margin-top-4 digits">
      <input
        *ngFor="let p of digits; let i = index"
        tabindex="{{ i + 1 }}"
        type="text"
        maxlength="1"
        [(ngModel)]="p.digit"
      />
    </div>

    <div class="margin-top-2">
      <span>{{ code ? "Code Sent!... " : "" }}</span> &nbsp;
      <a class="f-regular" (click)="sendCode('phone')">Resend Code</a>
    </div>

    <ion-button class="margin-top-2" color="warning" (click)="verify('phone')">
      Verify
    </ion-button>
  </div> -->

  <!-------success--------->

  <!-- *ngIf="authVerif?.phoneVerified && !authVerif?.emailVerified && !next" -->

  <!-- <div
    class="f-big center message green"
    *ngIf="!authVerif?.emailVerified && !next"
  >

  <
    <div>
      <ion-icon name="checkmark-outline" class="5-0"></ion-icon>
    </div>
    <div>
      Number ending in...{{ phone.substr(-4, 4) }} <br />
      is verified
    </div>
    <div>
      <ion-button class="margin-top-2" color="warning" (click)="nextVerif()">
        Next
      </ion-button>
    </div>
  </div> -->

  <!---------------Verify email----------------->

  <div class="f-big center message" *ngIf="next && !authVerif?.emailVerified">
    <div class="center">
      <img style="height: 60px" src="assets/extend/svg/confirmed.svg" />
    </div>
    <h4>Verify Email</h4>

    <div class="margin-top-1">
      A verification link has been sent to your email address. Please check your
      email and click on the link to verify your email address
      <span class="bold-600">{{
        email.substr(0, 2) + "***@" + email.split("@")[1]
      }}</span>
    </div>

    <div class="flex-center-2 margin-top-2 digits">
      <input type="text" maxlength="6" [(ngModel)]="digits" />
    </div>

    <ion-button class="margin-top-2" color="warning" (click)="verify('email')">
      Verify
    </ion-button>

    <div class="center margin-top-3">
      <a class="orange-dark" (click)="sendCode('email')">Resend Code</a>
    </div>
  </div>

  <!-------success--------->

  <div class="f-big center message green" *ngIf="authVerif?.emailVerified">
    <div>
      <ion-icon
        name="checkmark-outline"
        style="font-size: 3rem; margin-top: 3rem"
      ></ion-icon>
    </div>
    <div>
      Your email {{ email.substr(0, 2) + "***@" + email.split("@")[1] }}
      <br />
      is verified
    </div>
    <div>
      <ion-button class="margin-top-2" color="warning" (click)="close()">
        continue
      </ion-button>
    </div>
  </div>
</div>
